<template>
  <div
    class="gamelist-row"
    :class="name"
    ref="gamelistrow"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gamelist-row',

  props: {
    name: {
      type: [ String ]
    }
  },

  inject: {
    gamelistConfig: {}
  },

  data () {
    return {
      width: 0,
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getBodyWidth)
      this.getBodyWidth()

    })
  },

  methods: {
    getBodyWidth () {
      let gamelistRow = this.$refs.gamelistrow
      let totalWidth = 0

      if (this.name === 'row-head') {
        for (let i = 0; i < gamelistRow.children.length; i++) {
          totalWidth += gamelistRow.children[i].offsetWidth
        }

        setTimeout(() => {
          this.gamelistConfig.gamelist_w = totalWidth
        }, 1000)
      }
    }
  }
}

</script>
